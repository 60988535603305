<template>
    <header :class="$style.bodyHeader">
        <h1>{{ $route.meta.title }}</h1>
    </header>
</template>

<script>

export default {
    
    name: 'AppBodyHeader'

}

</script>

<style lang="scss" module>

.bodyHeader {

    background-color: #fff;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1);
    margin: 0 0 2rem 0;
    padding: 2rem 2rem;

    h1 {

        font-family: var(--secundary-font);
        font-size: 1.3rem;
        font-weight: 700;
        margin: 0;
        text-align: center;
        text-transform: uppercase;

    }

}

</style>